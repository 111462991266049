/* eslint-disable jsx-a11y/anchor-is-valid */
import { gql } from "@apollo/client";
import { faExternalLinkAlt, faPencil } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Link,
  makeStyles,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Badge } from "@resource/atlas";
import styles from "components/Guides/Guides.module.scss";
import BasePathEnum from "enums/BasePathEnum";
import { GuideRowItemGuide } from "generated/schemaTypes";
import moment from "moment-timezone";
import React from "react";
import useAnalytics from "react-hooks/useAnalytics";

import CandidateViewChart from "./CandidateViewChart";

const useStyles = makeStyles({
  candidateViewChartTooltip: {
    width: "400px",
    marginTop: "-20px",
    marginRight: "-60px",
  },
});

const GUIDE_ROW_ITEM_FRAGMENT_GUIDE = gql`
  fragment GuideRowItemGuide on Guide {
    id
    isDemo
    atsUrl
    publicUrl
    candidate {
      id
      firstName
      lastName
    }
    roleName
    currentStage {
      id
      nextInterview {
        id
        startTime
      }
      lastEmailActivityAt
    }
    lastEmailActivityAt
    candidateOpens
  }
`;

type GuideRowItemProps = {
  guide: GuideRowItemGuide;
  edit(): void;
};

function GuideRowItem({ guide, edit }: GuideRowItemProps) {
  const classes = useStyles();
  const analytics = useAnalytics();
  const {
    candidate,
    roleName,
    currentStage,
    id: guideId,
    publicUrl,
    isDemo,
  } = guide;
  const firstName = candidate?.firstName;
  const lastName = candidate?.lastName;
  const lastSentAt =
    "lastEmailActivityAt" in guide
      ? guide.lastEmailActivityAt
      : currentStage?.lastEmailActivityAt;
  const candidateOpens = guide?.candidateOpens || [];
  const name = `${firstName} ${lastName}`;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const interviewDate = currentStage?.nextInterview?.startTime
    ? moment.tz(currentStage.nextInterview.startTime, timezone)
    : "";

  const urlPath = publicUrl
    ? new URL(publicUrl).pathname
    : `${BasePathEnum.Guide}/${guideId}`;
  const previewUrl = urlPath;

  const onEditClick = (e: React.MouseEvent): void => {
    analytics.track("Edit Guide Clicked", {
      guideId,
    });
    if (edit) {
      edit();
      e.preventDefault();
    }
    e.stopPropagation();
  };

  return (
    <TableRow className={styles.guideItemRow} onClick={onEditClick}>
      <TableCell className="relative">
        <Typography>
          {name}
          {isDemo && <Badge className="ml-2">Example</Badge>}
        </Typography>
      </TableCell>
      <Tooltip
        PopperProps={{
          className: classes.candidateViewChartTooltip,
        }}
        onMouseEnter={() => {
          analytics.track("Candidate Opens Hovered", {
            guideId: guide.id,
          });
        }}
        title={<CandidateViewChart candidateOpens={candidateOpens} />}
      >
        <TableCell>
          <Typography>{candidateOpens.length}</Typography>
        </TableCell>
      </Tooltip>
      <TableCell>
        <Typography>
          {interviewDate && interviewDate.format("MMM D")}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>{roleName}</Typography>
      </TableCell>
      <TableCell>
        {lastSentAt && <>{moment(lastSentAt).format("MMM D, YYYY")}</>}
      </TableCell>
      <TableCell className={styles.linksCell}>
        <div className={styles.hoverIcons}>
          <Link
            title="Edit"
            aria-label="Edit"
            onClick={onEditClick}
            color="primary"
            className={styles.link}
          >
            <FontAwesomeIcon
              className={styles.rowIcon}
              icon={faPencil}
              size="lg"
              fixedWidth
            />
          </Link>
          <Link
            title="Preview"
            aria-label="Preview"
            component="a"
            color="primary"
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
            href={previewUrl}
            onClick={(e: React.MouseEvent): void => {
              analytics.track("View Guide Clicked", {
                guideId,
              });
              e.stopPropagation();
            }}
          >
            <FontAwesomeIcon
              className={styles.rowIcon}
              icon={faExternalLinkAlt}
              size="lg"
              fixedWidth
            />
          </Link>
        </div>
      </TableCell>
    </TableRow>
  );
}

GuideRowItem.fragments = {
  guide: GUIDE_ROW_ITEM_FRAGMENT_GUIDE,
};

export default GuideRowItem;
