import { gql } from "@apollo/client";
import { Icon } from "@resource/atlas";
import { atlasRingInfo } from "@resource/atlas/icons";
import { useAuthContext } from "auth/context";
import ErrorBoundary from "components/ErrorBoundary";
import Error from "components/Generic/ErrorPage";
import GuidesTable from "components/GuidesTable";
import { useCurrentQueryParamStateWithDefaults } from "components/Table/hooks/useQueryParamState";
import { RoleEnum } from "enums/role-enum";
import {
  GuidesForHomepage,
  GuidesForHomepageVariables,
  OrderByArg,
  OrganizationGuidesOrderByInput,
} from "generated/schemaTypes";
import _ from "lodash";
import { formatEntity } from "shared/constants/entities";
import useQuery from "utils/useQuery";

const GUIDES_FOR_HOMEPAGE = gql`
  query GuidesForHomepage(
    $search: String
    $limit: Int!
    $offset: Int!
    $orderBy: [OrganizationGuidesOrderByInput!]
  ) {
    currentUser {
      id
      currentOrganization {
        id
        ...GuideTableRowOrganizationFragment
        guidesV2(
          limit: $limit
          offset: $offset
          orderBy: $orderBy
          where: { search: $search }
        ) {
          ...GuideTableRowGuide
        }
        guidesCount(where: { search: $search })
      }
    }
  }
  ${GuidesTable.fragments.guide}
  ${GuidesTable.fragments.organization}
`;

function Guides() {
  const {
    pageIndex,
    pageSize,
    globalFilter: search,
    sortBy,
  } = useCurrentQueryParamStateWithDefaults({
    sortBy: [{ id: "lastSentAt", desc: false }],
  });

  const initialVariables = {
    search,
    limit: pageSize,
    offset: pageIndex * pageSize,
    orderBy: _(sortBy)
      .map((sort): OrganizationGuidesOrderByInput => {
        const descVal = sort.desc ? OrderByArg.desc : OrderByArg.asc;
        const dateDescVal = sort.desc ? OrderByArg.asc : OrderByArg.desc;

        if (sort.id === "lastSentAt") {
          return {
            denormLastEmailActivityAt: dateDescVal,
          };
        }
        if (sort.id === "title") {
          return {
            candidate: {
              firstName: descVal,
            },
          };
        }

        return {
          [sort.id]: descVal,
        };
      })
      .value(),
  };

  const { data: guidesData, loading } = useQuery<
    GuidesForHomepage,
    GuidesForHomepageVariables
  >(GUIDES_FOR_HOMEPAGE, {
    variables: initialVariables,
    fetchPolicy: "cache-and-network",
  });

  const guides = guidesData?.currentUser?.currentOrganization?.guidesV2 || [];
  const totalGuidesCount =
    guidesData?.currentUser?.currentOrganization?.guidesCount || guides.length;
  const organization =
    guidesData?.currentUser?.currentOrganization ?? undefined;

  return (
    <GuidesTable
      records={guides}
      loading={loading}
      totalGuidesCount={totalGuidesCount}
      organization={organization}
      initialVariables={initialVariables}
      sortBy={sortBy}
      viewingMultipleJobs
      noResultsChildren={
        <>
          <h3 className="text-h3">
            No {formatEntity("guide", { plural: true })}
          </h3>
          <p className="text-body-md text-subtle mt-2 max-w-xs">
            Create guides for candidates to coordinate interviews and share
            updates.
          </p>
        </>
      }
    />
  );
}

export default function GuidesWrapper() {
  const { highestRole } = useAuthContext();
  const isInterviewer = highestRole === RoleEnum.Interviewer;

  return (
    <div className="space-y-8">
      <header className="text-display-2">Guides</header>
      <div>
        {isInterviewer && (
          <div className="flex space-x-2 p-4 w-full rounded-md bg-blue-50 mb-4">
            <Icon content={atlasRingInfo} className="text-blue-500" />
            <p className="text-body-md text-blue-900">
              The results below are limited to only those guides for candidates
              you are interviewing.
            </p>
          </div>
        )}
        <ErrorBoundary fallback={Error}>
          <Guides />
        </ErrorBoundary>
      </div>
    </div>
  );
}
