import {
  faLongArrowDown,
  faLongArrowUp,
  faSortAlt,
  IconDefinition,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import styles from "components/Guides/Guides.module.scss";
import { GuideRowItemGuide as Guide } from "generated/schemaTypes";
import moment from "moment-timezone";

type DataLocation =
  | string
  | ((guide: Guide) => number)
  | ((guide: Guide) => Date | undefined);

export type RowSortType = {
  column: string;
  dataLocation: DataLocation;
  direction: "asc" | "desc" | false;
};

type GuidesTableHeaderProps = {
  disableSorting?: boolean;
  rowsSortState: RowSortType;
  setRowSortState: ({
    column,
    dataLocation,
    direction,
  }: {
    column: string;
    dataLocation: DataLocation;
    direction: "asc" | "desc" | false;
  }) => void;
};

const TABLE_ROWS = [
  {
    title: "Candidate",
    columnType: "name",
    dataLocation: "candidate.firstName",
    sortable: true,
  },
  {
    title: "Opens",
    columnType: "openTracking",
    dataLocation: (guide: Guide) => guide.candidateOpens?.length,
    sortable: false,
  },
  {
    title: "Interview",
    columnType: "date",
    dataLocation: (guide: Guide) => {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return guide?.currentStage?.nextInterview?.startTime
        ? moment
            .tz(guide.currentStage.nextInterview.startTime, timezone)
            .toDate()
        : undefined;
    },
    sortable: false,
  },
  {
    title: "Role",
    columnType: "role",
    dataLocation: "jobRole.name",
    sortable: true,
  },
  {
    title: "Sent",
    columnType: "lastSent",
    dataLocation: "",
    sortable: true,
  },
  {
    title: "",
    columnType: "actions",
    dataLocation: "",
    sortable: false,
  },
];

function GuidesTableHeader({
  disableSorting,
  rowsSortState,
  setRowSortState,
}: GuidesTableHeaderProps) {
  const handleRowSort = (
    updatedColumn: string,
    dataLocation: DataLocation
  ): void => {
    const { column, direction } = rowsSortState;
    let updatedDirection;

    if (column === updatedColumn) {
      if (direction === false) {
        updatedDirection = "desc";
      } else if (direction === "desc") {
        updatedDirection = "asc";
      } else {
        updatedDirection = false;
      }
    } else {
      updatedDirection = "desc";
    }

    setRowSortState({
      column: updatedColumn,
      dataLocation,
      direction: updatedDirection as "asc" | "desc" | false,
    });
  };
  const { column, direction } = rowsSortState;

  return (
    <TableHead>
      <TableRow>
        {TABLE_ROWS.map((row, index) => {
          const { dataLocation, title, columnType, sortable } = row;

          let icon: IconDefinition;
          if (column !== columnType || !direction) {
            icon = faSortAlt;
          } else {
            icon = direction === "asc" ? faLongArrowUp : faLongArrowDown;
          }
          return (
            <TableCell
              /* eslint-disable-next-line react/no-array-index-key */
              key={index}
            >
              {sortable && !disableSorting ? (
                <TableSortLabel
                  active={column === columnType && !!direction}
                  direction={direction || "asc"}
                  onClick={(): void => handleRowSort(columnType, dataLocation)}
                  IconComponent={() => (
                    <FontAwesomeIcon className={styles.icon} icon={icon} />
                  )}
                >
                  {title}
                </TableSortLabel>
              ) : (
                title
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export default GuidesTableHeader;
