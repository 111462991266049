import { useFlags } from "@resource/client-ffs";
import { CustomerPlanEnum } from "@resource/common";
import withPageAuthRequired from "auth/withPageAuthRequired";
import { useExtensionController } from "client/app/extension/__components/ExtensionControllerProvider";
import Guides from "components/Guides/Guides";
import GuidesDeprecated from "components/Guides/GuidesDeprecated";
import Layout from "components/Layout";
import FeatureFlags from "generated/FeatureFlags";
import inIframe from "utils/in-iframe";

function GuidesWrapper() {
  const { sendMessageToParent } = useExtensionController();
  const { [FeatureFlags.NEW_GUIDES_LIST]: newGuidesList } = useFlags();
  if (inIframe()) {
    sendMessageToParent({
      command: "reload",
    });
    return null;
  }

  return newGuidesList ? <Guides /> : <GuidesDeprecated />;
}

export default withPageAuthRequired(GuidesWrapper, {
  requiredPlan: CustomerPlanEnum.Platform,
  getLayout: (page) => <Layout>{page}</Layout>,
});
