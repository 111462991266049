import { gql, NetworkStatus, useQuery } from "@apollo/client";
import { Card, Fade, Table, TableBody, Typography } from "@material-ui/core";
import { Icon } from "@resource/atlas";
import { atlasRingInfo } from "@resource/atlas/icons";
import { useFlags } from "@resource/client-ffs";
import { useAuthContext } from "auth/context";
import { useFlagsLoading } from "components/IdentifyUserWrapper";
import GuideRowItem from "components/legacy/Activity/GuideRowItem";
import GuidesTableHeader, {
  RowSortType,
} from "components/legacy/Activity/GuidesTableHeader";
import Loading from "components/Loading";
import GlobalFilter from "components/Table/GlobalFilter";
import { RoleEnum } from "enums/role-enum";
import FeatureFlags from "generated/FeatureFlags";
import {
  GuidesList,
  GuidesList_currentUser_currentOrganization_guides as Guide,
  GuidesListVariables,
  OrderByArg,
  OrganizationGuidesOrderByInput,
} from "generated/schemaTypes";
import _ from "lodash";
import { useEffect, useState } from "react";
import { analytics } from "react-hooks/useAnalytics";
import InfiniteScroll from "react-infinite-scroll-component";

import styles from "./Guides.module.scss";

const GUIDE_LIMIT = 25;
const GUIDE_REFETCH_LIMIT = 50;

const FETCH_GUIDES_QUERY = gql`
  query GuidesList(
    $search: String
    $limit: Int!
    $offset: Int!
    $orderBy: [OrganizationGuidesOrderByInput!]
  ) {
    currentUser {
      id
      currentOrganization {
        id
        guides(
          limit: $limit
          offset: $offset
          orderBy: $orderBy
          where: { search: $search }
        ) {
          ...GuideRowItemGuide
        }
      }
    }
  }
  ${GuideRowItem.fragments.guide}
`;

export default function GuidesDeprecated() {
  const { highestRole } = useAuthContext();
  const isInterviewer = highestRole === RoleEnum.Interviewer;
  const [globalFilter, setGlobalFilter] = useState("");
  const { [FeatureFlags.GUIDES_FILTER_BY_NAME]: guidesFilterByNameFlag } =
    useFlags();
  const { loading: flagsLoading } = useFlagsLoading();
  let orderBy: OrganizationGuidesOrderByInput;
  const [allGuidesFetched, setAllGuidesFetched] = useState(false);
  const [rowsSortState, setRowSortState] = useState<RowSortType>({
    column: "lastSent",
    dataLocation: "",
    direction: "desc",
  });

  const onEditGuide = (atsUrl: string): void => {
    window.open(atsUrl);
  };

  const direction =
    rowsSortState.direction === "desc" ? OrderByArg.desc : OrderByArg.asc;
  switch (rowsSortState.column) {
    case "name":
      orderBy = {
        candidate: {
          firstName: direction,
        },
      };
      break;
    case "role":
      orderBy = {
        jobRole: {
          name: direction,
        },
      };
      break;
    case "lastSent":
    default:
      orderBy = {
        denormLastEmailActivityAt: direction,
      };
  }

  const {
    data: guidesData,
    fetchMore,
    networkStatus,
    loading,
  } = useQuery<GuidesList, GuidesListVariables>(FETCH_GUIDES_QUERY, {
    skip: flagsLoading,
    variables: {
      limit: GUIDE_LIMIT,
      offset: 0,
      ...(orderBy ? { orderBy: [orderBy] } : {}),
      ...(globalFilter
        ? {
            search: globalFilter,
          }
        : {}),
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const guides = data?.currentUser?.currentOrganization?.guides || [];
      if (guides.length < GUIDE_LIMIT) {
        setAllGuidesFetched(true);
      }
    },
  });
  const guidesLoading = loading && networkStatus !== NetworkStatus.fetchMore;
  const guides = guidesData?.currentUser?.currentOrganization?.guides || [];

  useEffect(() => {
    if (networkStatus === NetworkStatus.setVariables && allGuidesFetched) {
      setAllGuidesFetched(false);
    }
  }, [networkStatus, allGuidesFetched]);

  const handleInfiniteScroll = async () => {
    analytics.track("Activity Paginated");
    const { data } = await fetchMore<GuidesList, GuidesListVariables>({
      variables: {
        limit: GUIDE_REFETCH_LIMIT,
        offset: guides.length,
      },
    });
    const newGuides = data.currentUser?.currentOrganization?.guides ?? [];
    if (newGuides.length < GUIDE_REFETCH_LIMIT) {
      setAllGuidesFetched(true);
    }
  };

  return (
    <Fade in timeout={1000}>
      <div className={styles.guidesCont}>
        <div className={styles.headerCont}>
          <Typography className={styles.headerText} variant="h2">
            Guides
          </Typography>
        </div>
        <div className="mt-8">
          {guidesFilterByNameFlag && (
            <GlobalFilter
              placeholder="Search by name or email"
              globalFilter={globalFilter}
              setGlobalFilter={(newVal) => {
                setAllGuidesFetched(false);
                setGlobalFilter(newVal);
              }}
              className="mb-4"
            />
          )}
          <InfiniteScroll
            dataLength={guides.length}
            hasMore={!allGuidesFetched}
            loader={<Loading />}
            next={handleInfiniteScroll}
            // TODO: ideally this would be a ref, but since this is a child of the layout,
            // the ref is not available because it's mounted before the parent. This library
            // doesn't handle this well, so we use the node id as a workaround.
            scrollableTarget="app-layout-content"
          >
            {!guidesLoading && (
              <>
                {guides.length ? (
                  <>
                    {isInterviewer && (
                      <div className="flex space-x-2 p-4 w-full rounded-md bg-blue-50 mb-4">
                        <Icon
                          content={atlasRingInfo}
                          className="text-blue-500"
                        />
                        <p className="text-body-md text-blue-900">
                          The results below are limited to only those guides for
                          candidates you are interviewing.
                        </p>
                      </div>
                    )}
                    <Card className={styles.tableCont}>
                      <Table className={styles.table}>
                        <GuidesTableHeader
                          rowsSortState={rowsSortState}
                          setRowSortState={setRowSortState}
                        />
                        <TableBody className={styles.tableBody}>
                          {_.map(guides, (guide: Guide) => {
                            const { id } = guide;

                            return (
                              <GuideRowItem
                                key={id}
                                guide={guide}
                                edit={() => onEditGuide(guide.atsUrl)}
                              />
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Card>
                  </>
                ) : (
                  <div className="flex flex-col items-center justify-center space-y-2 mt-80">
                    <p className="text-body-md-heavy">No active guides</p>
                    <p className="text-body-md">
                      {isInterviewer
                        ? "You are currently not an interviewer on any active guides."
                        : "There are no active guides for your organization."}
                    </p>
                  </div>
                )}
              </>
            )}
          </InfiniteScroll>
        </div>
      </div>
    </Fade>
  );
}
